import { ButtonStylesParams, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { init } from "@socialgouv/matomo-next";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import { ReactElement, ReactNode, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./styles.css";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const queryClient = new QueryClient();

const MyApp = (props: AppPropsWithLayout) => {
  const { Component, pageProps } = props;

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_MATOMO_URL &&
      process.env.NEXT_PUBLIC_MATOMO_SITE_ID
    ) {
      init({
        url: process.env.NEXT_PUBLIC_MATOMO_URL,
        siteId: process.env.NEXT_PUBLIC_MATOMO_SITE_ID,
      });
    }
  }, []);

  return (
    <>
      <Head>
        <title>EnglischfürSchüler.de</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>

      {props.router.pathname &&
        props.router.pathname.substring(0, 7) !== "/admin/" && (
          <Script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=942de3fd-8970-4786-9905-0ca82bde4596"
          />
        )}

      <QueryClientProvider client={queryClient}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            components: {
              Anchor: {
                styles: {
                  ".mantine-Table-root &": {
                    fontSize: "14px",
                  },
                },
              },

              Button: {
                styles: (theme, params: ButtonStylesParams) => ({
                  root: {
                    ":before": {
                      backgroundImage: undefined,
                      borderRadius: "8px",
                      content: '""',
                      display: "block",
                      height: "100%",
                      left: "4px",
                      position: "absolute",
                      top: "3px",
                      transform: "rotate(.5deg)",
                      width: "100%",
                      zIndex: -10,
                    },
                    ":hover": {
                      backgroundImage: undefined,
                      color: undefined,
                    },
                    background: "none",
                    border: "1px solid #540C50",
                    borderRadius: "8px",
                    color: "#540C50",
                  },
                }),
                variants: {
                  filled: (theme, params) => ({
                    root: {
                      ":before": {
                        backgroundImage:
                          "linear-gradient(to bottom right, #4DD6BA, #AED5CD)",
                      },
                      ":hover": {
                        backgroundImage:
                          "linear-gradient(to bottom right, #540C50, #7f497c)",
                        color: "white",
                      },
                      backgroundColor:
                        (theme.primaryColor || params.color) &&
                        theme.colors[params.color || theme.primaryColor]
                          ? theme.colors[params.color || theme.primaryColor]![9]
                          : undefined,
                    },
                  }),

                  light: (theme) => ({
                    root: {
                      ":before": {
                        backgroundImage:
                          "linear-gradient(to bottom right, #ddd, #eee)",
                      },
                    },
                  }),
                },
              },

              Title: {
                styles: {
                  root: {
                    transform: "rotate(-.5deg)",
                  },
                },
              },
            },

            colorScheme: "light",

            colors: {
              "e4s-teal": [
                "#E6EFED",
                "#CAE0DB",
                "#AED5CD",
                "#90D0C3",
                "#71D0BC",
                "#4DD6BA",
                "#45C1A8",
                "#47A592",
                "#488C7E",
                "#47786E",
              ],
              "e4s-purple": [
                "#f2d8ec",
                "#5C315A",
                "#592856",
                "#571F53",
                "#551551",
                "#540C50",
                "#550350",
                "#450A42",
                "#390E36",
                "#2F112E",
              ],
            },

            headings: { fontFamily: "Patrick Hand" },

            primaryColor: "e4s-purple",
          }}
        >
          <Notifications />
          {getLayout(<Component {...pageProps} />)}
        </MantineProvider>
      </QueryClientProvider>
    </>
  );
};

export default MyApp;
